<template>
  <el-container>
    <el-main>
      <router-link to="/extension/community/add">
        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
        >发布论坛</el-button>
      </router-link>
      <el-form class="sesrch">
        <div class="order-form-item" style="margin-bottom:10px">
          <el-form-item label="发帖标题：">
            <el-input
              size="small"
              placeholder="请输入发帖标题"
              v-model="form.title"
            ></el-input>
          </el-form-item>
          <el-form-item label="发布人信息：">
            <el-input
              size="small"
              placeholder="请输入发布人昵称"
              v-model="form.nick_name"
            ></el-input>
          </el-form-item>
          <div class="item">
            <span>发布日期：</span>
            <div style="flex:1;">
                 <el-date-picker
             value-format="timestamp"
              v-model="form.timeVal"
              size="small"
              type="daterange"
              range-separator="~"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
            </div>
          </div>
        </div>
        <div class="order-form-item">
        <el-form-item label="是否显示：">
          <el-select
            size="small"
            v-model="form.is_show"
            placeholder="请选择"
          >
            <el-option
              v-for="item in show"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否置顶：">
          <el-select
            size="small"
            v-model="form.is_tops"
            placeholder="请选择"
          >
            <el-option
              v-for="item in show"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-button
            size="small"
            type="primary"
            @click="getList()"
          >搜索</el-button>
          <el-button
            size="small"
            type="text"
            @click="cancelSearch"
          >清空搜索条件</el-button>
        </el-form-item>
        </div>

      </el-form>
      <div class="article-list">
        <el-table
          :data="list"
          :header-cell-style="{ 'background-color': '#F8F9FA' }"
        >
          <template slot="empty">
            <No />
          </template>

          <el-table-column
            prop="create_time"
            label="发帖时间"
            align="center"
          >
             <template slot-scope="scope">
              <span>{{ getDateformat(scope.row.create_time) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="title"
            label="发帖标题"
            align="center"
          >
          <template slot-scope="scope">
              <div class="table_title">{{ scope.row.title }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="cate_name"
            label="分类名称"
            align="center"
          ></el-table-column>
             <el-table-column
            prop="nick_name"
            label="发布人"
            align="center"
          >
          <template slot-scope="scope">
              <div class="table_title">{{ scope.row.nick_name }}</div>
            </template>
          </el-table-column>
           <el-table-column
            prop="share_num"
            label="分享次数"
            align="center"
          ></el-table-column>
           <el-table-column
            prop="like_num"
            label="点赞次数"
            align="center"
          ></el-table-column>
           <el-table-column
            prop="visit_num"
            label="浏览次数"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="is_show"
            label="是否显示"
            align="center"
          >
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.is_show"
                :active-value="1"
                :inactive-value="0"
                @change="tableChange(scope.row)"
              ></el-switch>
            </template>
          </el-table-column>
           <el-table-column
            prop="is_tops"
            label="是否置顶"
            align="center"
          >
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.is_tops"
                :active-value="1"
                :inactive-value="0"
                @change="tableChange(scope.row)"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="120"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                @click="detail(scope.row.id)"
                type="text"
                size="small"
              >查看</el-button>
              <el-button
                @click="remove(scope.row.id)"
                type="text"
                size="small"
              >删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <Paging
        :total="total"
        :page="form.page"
        :pageNum="form.rows"
        @updatePageNum="updateData"
      ></Paging>
    </el-main>
  </el-container>
</template>

<script>
import Paging from '@/components/paging.vue'
import { getDateformat } from '@/util/getDate'
export default {
  components: {
    Paging
  },
  data () {
    return {
      total: 0,
      form:{
        page: 1,
        rows: 10,
        title: '',
        nick_name:"",
        timeVal: '',
        is_show:-1,
        is_tops:-1
      },
      show: [
        {
          value: -1,
          label: '全部'
        },
        {
          value: 1,
          label: '是'
        },
        {
          value: 0,
          label: '否'
        }
      ],
      typeList: [],
      list: []
    }
  },
  created () {

    let utils = this.$store.state.utils
    if (utils.is_record) {
      this.form.page = utils.page
      this.form.rows = utils.rows
      utils.is_record = !1
    }
    this.getType()
    this.getList()

  },
  methods: {
    getType () {
      this.$axios
        .post(this.$api.samecity.community.classifyList)
        .then((res) => {
          if (res.code == 0) {
            this.typeList = res.result.list.list
          }
        })
    },
    cancelSearch () {
      this.form = {
        page: 1,
        rows: 10,
        title: '',
        nick_name:"",
        timeVal: '',
        is_show:-1,
        is_tops:-1
      }
      this.getList()
    },
    updateData (val, status) {
      if (status == 0) {
        this.form.rows = val
        this.getList()
      } else {
        this.form.page = val
        this.getList()
      }
    },
    getList () {
        let data = this.form
        if(data.timeVal){
            let arr = []
            data.timeVal.forEach(item=>{
                arr.push(item/1000)
            })
            data.create_time = arr
        }
        this.$axios.post(this.$api.samecity.community.postsList, data)
        .then((res) => {
            if (res.code == 0) {
                let list = res.result.list
                this.list = list
                this.total = res.result.total
            } else {
                this.$message.error(res.msg)
            }
        })
    },
    detail(id) {
      this.$router.push({
        path: '/extension/community/detail',
        query: {
          id: id,
          page: this.form.page,
          rows: this.form.rows
        }
      })
    },
    remove (id) {
      this.$confirm('确认删除此帖子？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$axios
            .post(this.$api.samecity.community.postsDelete, {id})
            .then((res) => {
              if (res.code == 200) {
                this.$message({
                  message: '删除成功',
                  type: 'success'
                })
                this.getList()
              } else {
                this.$message.error(res.msg)
              }
            })
        })
        .catch((err) => { })
    },
    tableChange (row) {
        let form = {
            title: row.title,
            cate_id:row.cate_id,
            avater:row.avater,
            nick_name:row.nick_name,
            is_show:row.is_show,
            is_tops:row.is_tops,
            content_type:row.content_type,
            content:row.content,
            content_data:row.content_data,
            id:row.id
        }
      this.$axios.post(this.$api.samecity.community.postsEdit, form).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: '编辑成功',
            type: 'success'
          })
          this.getList()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    getDateformat (val) {
      return getDateformat(val)
    }
  }
}
</script>

<style lang="less" scoped>
.el-table {
    border-left: 1px solid #e8eef1;
    border-right: 1px solid #e8eef1;
    /deep/ .el-input__inner {
        border-color: #fff;
    }
    /deep/ .el-input__inner:focus {
        border-color: #409eff;
    }
}
.el-container {
    .el-main {
        height: 100%;
        background: #fff;
        display: flex;
        flex-direction: column;
        .sesrch {
            margin-top: 10px;
            margin-bottom: 10px;
            padding: 10px 15px;
            background: #f8f9fa;
            .order-form-item {
                display: flex;
                color: #606266;
                .item {
                    margin-right: 20px;
                    width: 350px;
                    display: flex;
                    align-items: center;
                    span {
                         width:88px;
                        font-size: 14px;
                        margin-right:12px;
                        text-align: right;
                    }
                    .el-date-editor{
                        width: 100%;
                    }
                }
            }
            .el-form-item {
                margin-bottom: 0;
                margin-right: 20px;
                display: flex;
                .el-input {
                    width: 250px;
                }
                .el-select {
                    width: 250px;
                }
                /deep/.el-form-item__label{
                    width:100px;
                }
            }
        }
        .article-list {
            flex: 1;
        }
    }
}
.table_title{
    text-align: left;
     text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
